<template>
    <searchBody>
        <search class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ { 0: '关闭', 1: '启用' }[row.status] }}</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <!-- <Button type="error" size="small" ghost @click="onDelete(row)">删除</Button> -->
                        <Button type="primary" size="small" ghost @click="onCreate(row.id)">编辑</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqDelServe, reqServeList } from '@/lib/request/auth';

export default {
    name: 'serviceManagerList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            searchs: [
                { id: hm.createId(), label: '编号', placeholder: '输入编号', type: 'input', bind: 'id' },
                { id: hm.createId(), label: '服务名称', placeholder: '输入关键字', type: 'input', bind: 'title' },
                {
                    id: hm.createId(),
                    label: '状态',
                    placeholder: '选择状态',
                    type: 'select',
                    bind: 'status',
                    list: [
                        { id: '1', title: '启用' },
                        { id: '0', title: '关闭' },
                    ],
                },
            ],
            dataColumn: [
                { key: 'id', title: '编号', minWidth: 80, tooltip: true },
                { key: 'title', title: '服务名称', minWidth: 150, tooltip: true },
                { key: 'note', title: '服务介绍', minWidth: 150, tooltip: true },
                { slot: 'status', title: '状态', minWidth: 100 },
                // { key: 'createtime', title: '创建时间', minWidth: 120 },
                { slot: 'manager', title: '操作', minWidth: 120 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        //查询列表数据
        getList() {
            this.showAjaxLoading();
            reqServeList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新增/编辑
        onCreate(id) {
            this.goUrl('/serviceAdd', {
                id,
            });
        },
        //删除
        onDelete(info) {
            let { id, title } = info;
            this.confirmDialog(`确认删除【${title}】吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    reqDelServe({ id })
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.getList();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                }
            });
        },
    },
};
</script>
